
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { formatDate } from '@/utils/date'
import { ElForm } from 'element-ui/types/form'

@Component({
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i:s', value) : '-'
		}
	}
})
export default class extends Emitter {
	public $refs!: {
		'logistics-form': ElForm
	}
	private now = Math.floor(Date.now() / 1000)
	private logisticsForm = {
		id: null,
		visible: false,
		logistics_name: '',
		logistics_number: ''
	}
	private validateLogisticsForm = {
		logistics_name: [
			{ required: true, message: '请输入或者选择快递公司', trigger: 'blur' }
		],
		logistics_number: [
			{ required: true, message: '请输入快递单号', trigger: 'blur' }
		]
	}
	/**
	 * 常用快递公司
	 */
	private logisticsList = [
		{
			key: 'SF',
			name: '顺丰快递'
		},
		{
			key: 'ZTO',
			name: '中通快递'
		},
		{
			key: 'YTO',
			name: '圆通快递'
		},
		{
			key: 'STO',
			name: '申通快递'
		},
		{
			key: 'YUNDA',
			name: '韵达快递'
		},
		{
			key: 'EMS',
			name: 'EMS'
		},
		{
			key: 'GTO',
			name: '国通快递'
		},
		{
			key: 'HTKY',
			name: '百世快递'
		},
		{
			key: 'YZPY',
			name: '邮政快递包裹'
		},
		{
			key: 'HHTT',
			name: '天天快递'
		},
		{
			key: 'JD',
			name: '京东快递'
		},
		{
			key: 'UC',
			name: '优速快递'
		},
		{
			key: 'DBL',
			name: '德邦快递'
		},
		{
			key: 'ZJS',
			name: '宅急送'
		},
		{
			key: 'JT',
			name: '极兔快递'
		}
	]

	private searchForm = {
		packageCode: '',
		userCode: '',
		status: ''
	}

	private pageConfig = {
		total: 0,
		size: 20,
		current_page: 1
	}
	@Watch('pageConfig.current_page')
	onPagination() {
		this.search()
	}

	private list: any = []
	created() {
		const { size, current_page }: any = this.$route.query
		this.pageConfig.size = Number(size || 20)
		this.pageConfig.current_page = Number(current_page || 1)
		this.search(true)
	}
	resetSearch() {
		this.searchForm.packageCode = ''
		this.searchForm.packageCode = ''
		this.search(true)
	}
	async search(reset?: boolean) {
		if (reset) {
			this.pageConfig.current_page = 1
		}
		const { packageCode, userCode, status } = this.searchForm
		const { size, current_page } = this.pageConfig

		const { data } = await this.$axios
			.get('/v1/jobline/return/packages', {
				params: {
					user_code: userCode,
					package_code: packageCode,
					status,
					page_size: size,
					page_index: current_page
				}
			})
			.catch(e => {
				return { data: null }
			})

		this.$router
			.replace({
				query: {
					package_code: packageCode,
					user_code: userCode,
					size: String(size),
					current_page: String(current_page)
				}
			})
			.catch(err => err)
		this.list = data.list
		this.pageConfig.total = data.page.total
	}

	openLogisticsDialog({ row, $index }: any) {
		this.logisticsForm.visible = !this.logisticsForm.visible
		this.logisticsForm.id = row.id
		this.logisticsForm.logistics_name = row.express_company || ''
		this.logisticsForm.logistics_number = row.express_number || ''
	}

	async updateLogisticsInfo() {
		const isValidate = await this.$refs['logistics-form']
			.validate()
			.catch(() => false)
		if (!isValidate) return
		const { logistics_name, logistics_number, id } = this.logisticsForm
		const postData = {
			logistics_name,
			logistics_number,
			id
		}
		const { data } = await this.$axios
			.post('/v1/jobline/return/packages/logistics', postData)
			.catch(() => {
				return {
					data: null
				}
			})

		if (data) {
			this.$message.success('更新成功!')
			this.logisticsForm.visible = false
			this.search(false)
		}
	}
}
