var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/warehouse" } } }, [
            _vm._v("仓储"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("退件")]),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c("div", { attrs: { gutter: 30, span: 24 } }, [
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-form",
                {
                  ref: "search-form",
                  staticClass: "search-form",
                  attrs: { inline: true, model: _vm.searchForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return (() => {}).apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "包裹单号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "包裹单号", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.search(true)
                          },
                        },
                        model: {
                          value: _vm.searchForm.packageCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchForm,
                              "packageCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "searchForm.packageCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户编码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "用户编码", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.search(true)
                          },
                        },
                        model: {
                          value: _vm.searchForm.userCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchForm,
                              "userCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "searchForm.userCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "退件状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          on: {
                            change: function ($event) {
                              return _vm.search(true)
                            },
                          },
                          model: {
                            value: _vm.searchForm.status,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.searchForm,
                                "status",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "searchForm.status",
                          },
                        },
                        [
                          _c(
                            "el-option",
                            { attrs: { label: "全部", value: "" } },
                            [_vm._v(" 全部 ")]
                          ),
                          _c(
                            "el-option",
                            { attrs: { label: "待退件", value: "2" } },
                            [_vm._v(" 待退件 ")]
                          ),
                          _c(
                            "el-option",
                            { attrs: { label: "已退件", value: "1" } },
                            [_vm._v(" 已退件 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "z-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.search(true)
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _c("z-button", { on: { click: _vm.resetSearch } }, [
                        _vm._v("重置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.list, stripe: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "package_return_num",
                      label: "退件单号",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "user.code",
                      label: "用户编码",
                      width: "120px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.user.code) + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "package_attr_nums",
                        label: "包裹信息",
                        width: "180px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-popover",
                                { attrs: { placement: "right" } },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: { data: scope.row.package_attrs },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          width: "150",
                                          property: "package_attr_num",
                                          label: "单号",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (packageAttrsScope) {
                                                return [
                                                  _c(
                                                    "el-link",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                        underline: false,
                                                        target: "_blank",
                                                        href: `/dashboard?logistics_code=${packageAttrsScope.row.package_attr_num}`,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          packageAttrsScope.row
                                                            .package_attr_num
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          width: "100",
                                          property: "shelf_code",
                                          label: "货架号",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.package_attrs[0]
                                              .package_attr_num
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "span",
                          [
                            _vm._v(" 包裹信息 "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "点击包裹单号显示详情",
                                  placement: "top",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-info" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: {
                      prop: "package_attr_nums",
                      label: "包裹数量",
                      "min-width": "80px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.package_attr_nums.length) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "收件人信息",
                      "min-width": "300px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("p", [_vm._v(_vm._s(scope.row.recipient))]),
                            _c("p", [_vm._v(_vm._s(scope.row.mobile))]),
                            _c("p", [_vm._v(_vm._s(scope.row.address))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "remark",
                      label: "备注",
                      "min-width": "300px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("p", [_vm._v(_vm._s(scope.row.remark))])]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status",
                      label: "状态",
                      "min-width": "100px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status === 1
                              ? _c(
                                  "el-tag",
                                  { attrs: { size: "mini", type: "success" } },
                                  [_vm._v(_vm._s(scope.row.status_text))]
                                )
                              : _c("el-tag", { attrs: { size: "mini" } }, [
                                  _vm._v(_vm._s(scope.row.status_text)),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "express_company",
                      label: "快递公司",
                      "min-width": "180px",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "express_number",
                      label: "快递单号",
                      "min-width": "200px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.express_number) + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "return_time",
                      label: "退件时间",
                      "min-width": "180px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatDate")(scope.row.return_time)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "applicant",
                      label: "申请人",
                      "min-width": "120px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.applicant.name || "-") +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "created",
                      label: "申请时间",
                      "min-width": "180px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatDate")(scope.row.created)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ctrl",
                      fixed: "right",
                      align: "center",
                      label: "操作",
                      "min-width": "180px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            !scope.row.return_time ||
                            _vm.now - scope.row.return_time < 86400 * 3
                              ? _c(
                                  "z-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openLogisticsDialog(scope)
                                      },
                                    },
                                  },
                                  [_vm._v("填入快递单号")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("z-pagination", {
                attrs: {
                  total: _vm.pageConfig.total,
                  "page-size": _vm.pageConfig.size,
                  "current-page": _vm.pageConfig.current_page,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pageConfig, "current_page", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pageConfig, "current_page", $event)
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "填写快递单号",
            visible: _vm.logisticsForm.visible,
            width: "480px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.logisticsForm, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "logistics-form",
              attrs: {
                model: _vm.logisticsForm,
                rules: _vm.validateLogisticsForm,
                "label-width": "80px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "inline-from" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递公司", prop: "logistics_name" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "select",
                          attrs: {
                            filterable: "",
                            "allow-create": "",
                            placeholder: "请选择快递公司",
                            clearable: "",
                          },
                          model: {
                            value: _vm.logisticsForm.logistics_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.logisticsForm, "logistics_name", $$v)
                            },
                            expression: "logisticsForm.logistics_name",
                          },
                        },
                        _vm._l(_vm.logisticsList, function (item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { label: item.name, value: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: ".inline-from" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递单号", prop: "logistics_number" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "off",
                          placeholder: "请输入快递单号",
                          clearable: "",
                        },
                        model: {
                          value: _vm.logisticsForm.logistics_number,
                          callback: function ($$v) {
                            _vm.$set(_vm.logisticsForm, "logistics_number", $$v)
                          },
                          expression: "logisticsForm.logistics_number",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.logisticsForm.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.updateLogisticsInfo },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }